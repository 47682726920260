export const isProduction = true;
export const googlemapsapikey = 'AIzaSyDT8u2W9mcuJ-uESWb9ZAcd6sd6hkmAMVs';
export const environment = Object.freeze({
  production: true,
  brand: 'bt',
  reclameAquiUrl: 'https://raresponde.reclameaqui.com.br/faleconosco/index/?client=9714',
  bizutUrl: 'https://www.bizut.me/vagas/publicas/725',
  domain: 'bodytech.com.br',

  // tslint:disable:max-line-length
  seo: {
    DEFAULT_FULL_TITLE: 'Bodytech Company',
    DEFAULT_TITLE: 'Bodytech',
    DEFAULT_META_DESCRIPTION: 'Venha treinar na Bodytech. Aqui você conta com os melhores profissionais e programas exclusivos de musculação e aulas coletivas para cuidar de sua saúde física e mental.',
  },

  sentry: {
    dsn: 'https://f220093b8f384507b02c7d31d31df733@sentry.dev.bodytech.company/5',
    enabled: true,
    environment: 'prod',
    release: 'btportal-__SENTRY_RELEASE__',
    attachStacktrace: true,
  },

  services: {
    baseUrl: 'https://services.bodytech.company',
    evoBaseUrl: 'https://evo-integracao.w12app.com.br',
  },

  recaptchaKey: '6Le3inEUAAAAAM1dWWSm-mh9aYQEiSXbQJl1ulxT',

  wordpress: {
    baseUrl: 'https://blog.bodytech.com.br',
  },

  apollo: {
    baseUrl: 'https://www.bodytech.com.br/api',
    microservicesBaseUrl: 'https://services.authentication.prd.bodytech.company/graphql'
  },

  docmod: {
    microservicesBaseUrl: 'https://services.docmod.prd.bodytech.company/graphql',
    key: 'd98ff7f5-e812-40e5-bb1f-e4b7c1144e2a',
    system: 'PORTAL',
    xApiKey: 'da2-pe5teasgxrcwne2bdv2ujhsjjm',
  },

  googleAnalytics: {
    trackingID: 'UA-30083028-1',
    tagManagerId: 'G-CZP52HY51Y',
    tagDmpId: 'GTM-T4FHWJGP',
  },

  facebook: {
    pixelId: '1744043536047158',
  },

  s3buckets: {
    images: 'https://images.bodytech.company',
    medias: 'https://medias.bodytech.company',
  },

  evo: {
    scheduleBaseUrl: 'https://admin.bodytech.com.br/bodytech',
  },
});
